@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import "~include-media/dist/_include-media.scss";

$breakpoints: (phone: 320px, tablet: 768px, desktop: 1440px);
$fa-font-path: '@fortawesome/fontawesome-free/webfonts';

#joinus_container {
  display: flex;
  flex-direction: column;
}

#joinus_title_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @include media("<tablet") {
    flex-direction: column;
    width: 100%;
  }
}

#joinus_intro_paragraph {
  width: 50%;
  margin-right: 160px;
  margin-top: 50px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #67696E;
  text-align: left;

  @include media("<tablet"){
    width: 100%;
    margin-top: 0;
  }
}

#joinus_title {
  width: 50%;
  font-family: 'Muli', sans-serif;
  font-size: 40px;
  font-weight: 900;
  color: black;
  text-align: center;

  @include media("<tablet") {
    width: 100%;
    text-align:left;
  }
}

.joinus-element {
  font-family: "Montserrat", sans-serif;
    font-size: 17px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;

  @include media("<tablet") {
    flex-direction: column;
    width: 100%;
  }
}


.joinus-element-text-wrap {
  width: 65%;
  margin-left: 40px;

  @include media("<tablet") {
    width: 100%;
    margin-left: 0;
  }
}

.joinus-element-text {
  width: 346px;
  margin-right: 0;

  @include media("<tablet") {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15%;
  }
}

.joinus-card{
  display: flex;
  flex-direction: column;
  margin-left:auto;
  margin-right:auto;
}

.joinus-card-header{
  display: flex;
  flex-direction: row;
  font-size: 21px;
  font-weight: bold;
  margin-left: 10%;
}

.joinus-card-image-1{
  height: 66px;
  width:66px;
  margin-right: 2%;
  margin-top:5%;
}
.joinus-card-image-2{
  height: 58px;
  width:58px;
  margin-right: 2%;
  margin-top:6%;
}


#join{
  padding-bottom: 40px;
    flex-direction: row;
    margin: 0 auto;
  @include media("<tablet") {
    flex-direction: column;
  }
}

#join_form {
  display: flex;
  flex-grow: 1;
  @include media("<tablet") {
    width: 100%;
  }
}



#join_title {
   display: flex;
    flex-direction: column;
    font-family: "Muli", sans-serif;
    font-size: 40px;
    margin-right: 100px;
    font-weight: 900;
    width: 30%;
  @include media("<tablet") {
    flex-direction: column;
    font-size: 30px;
    width: 100%;
    margin: 0 auto;
  }
}

#join_title_text {
  margin-bottom: 25%;
  @include media("<tablet") {
    flex-direction: row;
    font-size: 30px;
    margin-top: 55%;
  }
}

#pavaalegre {
  @include media("<tablet") {
  }

}

#join_desc {
  font-family: "Montserrat", regular;
    font-size: 17px;
    margin-top: 3%;
    width: 30%;
  @include media("<tablet") {
    width: 95%;
    margin-left: 10px;
  }
}

.forminput {
  border-radius: 5%;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  @include media("<tablet") {
    margin-top: 13px;
    margin-bottom: 13px;
  }

}

input[type=checkbox] {
  margin-top: 20px;
  margin-bottom: 15px;
}

#join_button {
  margin-top: 20px;

}